<template>
  <div class="infoOpen">
    <div class="mode-banner">
      <img src="@/assets/info.jpg" alt="">
    </div>
    <div class="sub-menu">
      <div class="layer-wrapper">
        <div class="wrapper">
          <div class="title">
            <span>信息公开</span>
          </div>
        </div>
      </div>
    </div>
    <div class="newsinfo-page">
        <div class="layer-wrapper">
            <div class="content" style="padding-bottom: 15px">
              <div class="news-info-title">
                <div class="title">{{info.title}}</div>
              </div>
              <div class="source">
                <div class="time">{{info.dateDay}}</div>
              </div>
              <div v-html="info.content"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {getNewsDetail} from "../../api";
const info = ref('')
let id=window.location.href.split('=')
getNewsDetail({newsId:id[1]}).then(res=>{
    if(res.code==1){
      info.value=res.data;
    }
})
</script>

<style lang="scss" scoped>
.infoOpen{
  //width: 100%;
  height: 100%;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .mode-banner{
    width: 100%;
    height: 365px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .sub-menu{
    background-color: #f8f8f8;
    height: 45px;
    padding-bottom: 15px;
  }
}
.layer-wrapper{
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 150px;
  .wrapper{
    width: 100%;
    box-sizing: border-box;
    .title{
      float: left;
      min-width: 162px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      position: relative;
      top: -15px;
      box-sizing: border-box;
      span{
        display: block;
        position: relative;
        z-index: 2;
        background-color: #FFDA00;
        color: #fff;
        font-size: 24px;
        padding: 0 10px;
      }
    }
    .current-menu{
      font-size: 32px;
      line-height: 77px;
      border-bottom: 1px solid #eeeeee;
      position: relative;
    }
  }
}
.newsinfo-page{
  padding-top: 40px;
}
.news-info-title{
  padding-bottom: 20px;
  text-align: center;
  margin-top: 20px;
  .title{
    font-size: 34px;
    color: #040404;
    line-height: 54px;
  }
  .title:after {
    content: "";
    display: block;
    width: 56px;
    height: 4px;
    background-color: #FFDA00;
    margin: 0 auto;
    margin-top: 25px;
  }
}
.source{
  text-align: center;
  line-height: 32px;
  .time{
    font-size: 20px;
    color: #999;
    padding-bottom: 40px;
  }
}
</style>
